import { useState, useEffect } from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LoaderCarouselStyles } from './LoaderCarouselStyles';
import {
  LOADER_ALT_MSG,
  CAROUSEL_ONE_TITLE,
  CAROUSEL_TWO_TITLE,
  CAROUSEL_TWO_SUB_TITLE,
  PHOTO_DEPOSIT,
  LIVE_TELLER,
  AUTOMATED_TELLER_MACHINE,
  ATH_MOVIL,
  PEOPLE_PAY,
} from '../../constants';
import loader from '../../images/Loader.gif';
import { LoaderStyles } from './LoaderStyles';
import PhotoDepositImg from '../../images/photoDepositImg.svg';
import liveTellerImg from '../../images/liveTellerImg.svg';
import automatedTellerMachineImg from '../../images/automatedTellerMachineImg.png';
import ATHMovilImg from '../../images/ATHMovilImg.png';
import peoplePayImg from '../../images/peoplePayImg.svg';
import CarousalImg from '../../images/CarousalImg.png';

const useStyles = LoaderCarouselStyles();
const useLoaderStyles = LoaderStyles();

const LoaderCarousel = () => {
  const styledClasses = useStyles();
  const classes = useLoaderStyles();

  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints?.down('sm'), {
    defaultMatches: true,
  });

  const { t } = useTranslation();

  const item = [
    {
      id: 1,
      content: (
        <div className={styledClasses.contentBox} id="CarouselContent1">
          <div className={styledClasses.contentTextBox}>
            <Typography className={`${styledClasses.textImage1}`}>
              {t(CAROUSEL_ONE_TITLE, {
                defaultValue: CAROUSEL_ONE_TITLE,
              })}
            </Typography>
            <div className={styledClasses.iconsBox}>
              <Grid container spacing={1} className={styledClasses.iconsContainer}>
                <Grid item xs={4} sm={4} md={3} lg={2} className={styledClasses.icon}>
                  <div>
                    <img
                      src={PhotoDepositImg}
                      alt={`${t(PHOTO_DEPOSIT, {
                        defaultValue: PHOTO_DEPOSIT,
                      })} graphic`}
                      className={styledClasses.imgSize}
                    />
                  </div>
                  <div>
                    <Typography className={`${styledClasses.iconName}`}>
                      {t(PHOTO_DEPOSIT, {
                        defaultValue: PHOTO_DEPOSIT,
                      })}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4} sm={4} md={3} lg={2} className={styledClasses.icon}>
                  <div>
                    <img
                      src={liveTellerImg}
                      alt={`${t(LIVE_TELLER, {
                        defaultValue: LIVE_TELLER,
                      })} graphic`}
                      className={styledClasses.imgSize}
                    />
                  </div>
                  <div>
                    <Typography className={`${styledClasses.iconName}`}>
                      {t(LIVE_TELLER, {
                        defaultValue: LIVE_TELLER,
                      })}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4} sm={4} md={3} lg={2} className={styledClasses.icon}>
                  <div>
                    <img
                      src={automatedTellerMachineImg}
                      alt={`${t(AUTOMATED_TELLER_MACHINE, {
                        defaultValue: AUTOMATED_TELLER_MACHINE,
                      })} graphic`}
                      style={{ width: '52px', height: '52px' }}
                    />
                  </div>
                  <div>
                    <Typography className={`${styledClasses.ATMiconName}`}>
                      {t(AUTOMATED_TELLER_MACHINE, {
                        defaultValue: AUTOMATED_TELLER_MACHINE,
                      })}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4} sm={4} md={3} lg={2} className={styledClasses.icon}>
                  <div>
                    <img
                      src={ATHMovilImg}
                      alt={`${t(ATH_MOVIL, {
                        defaultValue: ATH_MOVIL,
                      })} graphic`}
                      className={styledClasses.imgSize}
                    />
                  </div>
                  <div>
                    <Typography className={`${styledClasses.iconName}`}>
                      {t(ATH_MOVIL, {
                        defaultValue: ATH_MOVIL,
                      })}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4} sm={4} md={3} lg={2} className={styledClasses.icon}>
                  <div>
                    <img
                      src={peoplePayImg}
                      alt={`${t(PEOPLE_PAY, {
                        defaultValue: PEOPLE_PAY,
                      })} graphic`}
                      className={styledClasses.imgSize}
                    />
                  </div>
                  <div>
                    <Typography className={`${styledClasses.iconName}`}>
                      {t(PEOPLE_PAY, {
                        defaultValue: PEOPLE_PAY,
                      })}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      ),
    },
    {
      id: 2,
      content: (
        <div id="carouselContent2">
          <Grid container>
            <Grid
              className={`${styledClasses.secondImageTextBox} ${styledClasses.contentBox}`}
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
            >
              <Typography className={`${styledClasses.textImage1}`}>
                {t(CAROUSEL_TWO_TITLE, {
                  defaultValue: CAROUSEL_TWO_TITLE,
                })}
              </Typography>
              <Typography className={`${styledClasses.textImage2}`}>
                {t(CAROUSEL_TWO_SUB_TITLE, {
                  defaultValue: CAROUSEL_TWO_SUB_TITLE,
                })}
              </Typography>
            </Grid>
            {!isMobile && (
              <Grid item className={styledClasses.carousalImage2} md={4} lg={4}>
                <img src={CarousalImg} alt="carousal_image_2" />
              </Grid>
            )}
          </Grid>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const id = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % item.length);
    }, 10000);

    return () => clearInterval(id);
  }, [currentIndex, item.length]);

  const handlerClick = (itemIndex) => {
    setCurrentIndex(itemIndex);
  };

  return (
    <div role="main" aria-describedby="loaderText CarouselContent1 carouselContent2">
      <div className={styledClasses.loaderBox}>
        <div data-testid="loader_id">
          <img src={loader} className={classes.loader_circle} alt={LOADER_ALT_MSG} />
        </div>
      </div>
      <div className={styledClasses.textBox} id="loaderText">
        <div style={{ marginBottom: '20px' }}>
          <Typography className={`${styledClasses.headingText}`}>
            {t('We are opening your account', {
              defaultValue: 'We are opening your account',
            })}
          </Typography>
        </div>
        <div className={`${styledClasses.subTextBox}`}>
          <Typography className={`${styledClasses.subText}`}>
            {t('This will take only few seconds, please do not close this window.', {
              defaultValue: 'This will take only few seconds, please do not close this window.',
            })}
          </Typography>
        </div>
      </div>
      <div>
        <div className={currentIndex === 0 ? styledClasses.slider1 : styledClasses.slider2}>
          {item.map((itemContent, index) => {
            const isActive = index === currentIndex;
            const className = isActive ? styledClasses.sliderItemActive : styledClasses.sliderItem;
            return (
              <div className={className} key={itemContent.id}>
                {itemContent.content}
              </div>
            );
          })}
        </div>
      </div>
      <div className={styledClasses.sliderControls}>
        <div className={styledClasses.sliderIndicator}>
          {item.map((itemContent) => (
            <span
              key={itemContent.id}
              onClick={() => {
                handlerClick(itemContent.id - 1);
              }}
              onKeyDown={() => {
                handlerClick(itemContent.id - 1);
              }}
              data-testid="carousel_testid"
              className={
                itemContent.id - 1 === currentIndex
                  ? styledClasses.sliderIndicatorItemActive
                  : styledClasses.sliderIndicatorItem
              }
              aria-describedby="carouselContent1 carouselContent2"
              tabIndex="0"
            >
              {}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoaderCarousel;
