/* eslint-disable max-lines */
import React, { useState, useEffect, forwardRef, useImperativeHandle, useContext } from 'react';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import axios from 'axios';
import { MessageList, MessageGroup } from '@livechat/ui-kit';
import { ChatWrapperCss } from '../utils/WrapperCss';
import {
  focusInChatbot,
  onLoadFunction,
  setMaxLengthForInputBox,
  feedbackProcessApiHandler,
  isLastMessageIncludes,
  endChatApiCall,
  base64Authorization,
  setChatBotIntents,
} from '../utils/CommonFunctions';
import ChatHeader from './containers/ChatHeader';
import ChatLogo from './containers/ChatLogo';
import ChatMessageContainer from './containers/ChatMessageContainer';
import ValidationProcess from './containers/ValidationProcess';
import Spinner from './spinner/Spinner';
import StepTwo from './wrapup/StepTwo';
import AgentFeedback from './wrapup/AgentFeedback';
import GeneralInfo from './liveAgent/GeneralInfo';
import { DIRA_ENG, EIRA_ENG, DIRA_ESP, EIRA_ESP, subDomains } from '../utils/CommonData';
import {
  BACK_TO_CHAT_ENG,
  BACK_TO_CHAT_ESP,
  CANCEL_THIS_PROCESS_ENG,
  CANCEL_THIS_PROCESS_ESP,
  CB_LANGUAGE_SWITCH_ENGLISH,
  CB_LANGUAGE_SWITCH_SPANISH,
  CHAT_WITH_BOT_ENDED_ENG,
  CHAT_WITH_BOT_ENDED_ESP,
  DESTINATION,
  END_CHAT_ENG,
  END_CHAT_ESP,
  LIVE_AGENT_CHAT_ENDED_ENG,
  LIVE_AGENT_CHAT_ENDED_ESP,
  NEED_ANYTHING_ELSE_ENG,
  NEED_ANYTHING_ELSE_ESP,
  OFCOURSE_WHAT_YOU_NEED_ENG,
  PLEASE_TELL_YOUR_NAME_ENG,
  PLEASE_TELL_YOUR_NAME_ESP,
  SHOW_FEEDBACK,
  TRAVEL_DATES,
  WHAT_TO_CHANGE_LANGUAGE_ENG,
  WHAT_TO_CHANGE_LANGUAGE_ESP,
  WHAT_YOU_NEED_ENG,
  WHAT_YOU_NEED_ESP,
  WILL_CONTACT_YOU_SOON_ENG,
  WILL_CONTACT_YOU_SOON_ESP,
  WORKING_HOURS_MON_TO_FRI_ENG,
  WORKING_HOURS_MON_TO_FRI_ESP,
  SELECT_DIRA_MSG_ENG,
  SELECT_DIRA_MSG_ESP,
  SELECT_EIRA_MSG_ENG,
  SELECT_EIRA_MSG_ESP,
  EDUCATIVE_TRADITIONAL_IRA_ENG,
  EDUCATIVE_TRADITIONAL_IRA_ESP,
} from '../Constants';
import { getParameters } from '../CommonFunction';

import GenerateLexUserId from '../connectivity/GenerateLexUserId';
import LeadGenerationContext from '../../../context/LeadGenerationContext';

const lexUserId = GenerateLexUserId();
const useChatWrapperCss = ChatWrapperCss();
const Maximized = forwardRef(
  (
    {
      SspUserLogin,
      customerGuid,
      state,
      lexSuccessHandler,
      afterPostLexCall,
      lexFailedHandler,
      minimize,
      messages,
      countriesList,
      handleCountriesList,
      sendToLex,
      handleMessage,
      handleWrapupMessage,
      onCloseClick,
      handleLanguageChange,
      handeLanguageChangeMessage,
      handeButtonLinkMessage,
      handleDateMessage,
      englishBot,
      lexError,
      defaultTextareaContent,
      sessionID,
      pegaIntent,
      slotToElicit,
      handlerPageIntent,
      handlePopupRunningCase,
      btnIntentTrigger,
      handlerIntentTrigger,
      runningAWSConnect,
      sendDocToConnect,
      waitingForLiveAgent,
      allowUploadDoc,
      awsConnectError,
      agentTypingFlag,
      awsConnectAgentName,
      customerDetails,
      ReconnectToAwsConnect,
      askingForWaitingTime,
      handleButtonClickDuringAgentCallMessage,
      liveAgentJoined,
      hideOnCustomerType,
      userEmail,
      setUserEmailFromIntent,
      verfiedCustomer,
      setVerifedCustomer,
      setReconnectionStatus,
      addMessages,
      deleteSessionToLex,
      formInProcessTrigger,
      setCustomerDetails,
      spinnerTrigger,
      spinnerHandler,
      cardValidFlag,
      sendToConnect,
      uploadDocuments,
      attachmentLoader,
      cancelUploadDocuments,
      iraReasonsList,
      handleEncryptedDataNeeded,
      handleMessages,
      handlePostLexToken,
      handleClearMessages,
      handleLanguage,
      isModalOpen,
      handlePostLexCatch,
      handleEnglishBot,
    },
    ref
  ) => {
    const WrapperCss = useChatWrapperCss();
    const { currentPage, language } = useContext(LeadGenerationContext);
    const [wrapEmail, setWrapEmail] = useState(userEmail);
    const [userName, setUserName] = useState('');
    const [closeTrigger, setCloseTrigger] = useState(false);
    const [agentFeedbackTrigger, setAgentFeedbackTrigger] = useState(false);
    const [newConciergeCustomer, setNewConciergeCustomer] = useState(false);
    const [existingConciergeCustomer, setExistingConciergeCustomer] = useState(false);
    const [showUploadingBar, setShowUploadingBar] = useState(false);
    const [sendBtnActive, setSendBtnActive] = useState(true);
    const [numericKeyboard, setNumericKeyboard] = useState(false);
    const dummyText = localStorage.getItem('textContent')
      ? localStorage.getItem('textContent')
      : defaultTextareaContent;
    const [defaultTextContent, setDefaultTextContent] = useState(dummyText);
    const [maxLength, setMaxLength] = useState(2000);
    const [isValid, setIsValid] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [serviceFormTrigger, setServiceFormTrigger] = useState(false);
    const [generalFormTrigger, setGeneralFormTrigger] = useState(false);
    const [webFormType, setWebFormType] = useState('');
    const [isCancelProcess, setIsCancelProcess] = useState(!!pegaIntent);
    const [isFeedbackProcess, setIsFeedbackProcess] = useState(
      !!(allowUploadDoc && liveAgentJoined)
    );
    const [errorMessage, setErrorMessage] = useState('');
    const [startDateTravel, setStartDateTravel] = useState('');
    const [endDateTravel, setEndDateTravel] = useState('');
    const getLangString = englishBot ? CB_LANGUAGE_SWITCH_ENGLISH : CB_LANGUAGE_SWITCH_SPANISH;
    const [intentLang, setIntentLang] = useState(getLangString);
    const [autoCompleteValue, setAutoCompleteValue] = useState('');
    const [showAutoComplete, setShowAutoComplete] = useState(false);
    const [autoCompleteFlag, setAutoCompleteFlag] = useState(true);
    const [showRegisterCustomerButton, setShowRegisterCustomerButton] = useState(false);
    const [countiresFinal, setCountiresFinal] = useState(false);
    const [dropDownValues, setDropDownValues] = useState([]);
    const [textComposerKey, setTextComposerKey] = useState(2);
    const hiddenFileInput = React.useRef(null);
    useImperativeHandle(ref, () => ({
      // eslint-disable-next-line no-restricted-syntax
      closeChatbot() {
        handlerIntentTrigger();

        setIsCancelProcess(false);
        setIsFeedbackProcess(false);
        onCloseClick();
        minimize();
        localStorage.removeItem('textContent');
        setDefaultTextContent('');
        setTextComposerKey((prev) => prev * 2);
        handlerPageIntent();
        setReconnectionStatus();
        deleteSessionToLex();
      },
    }));
    useEffect(() => {
      focusInChatbot(messages);
      if (messages.length > 0) {
        setMaxLengthForInputBox(
          slotToElicit,
          setMaxLength,
          setNumericKeyboard,
          setAutoCompleteFlag
        );
        if (pegaIntent) {
          setIsCancelProcess(true);
        }
        if (pegaIntent && slotToElicit === DESTINATION && !countiresFinal && autoCompleteFlag) {
          setStartDateTravel('');
          setEndDateTravel('');
          setShowAutoComplete(true);
        }
        if (!cardValidFlag) {
          setShowAutoComplete(false);
        }
        if (pegaIntent && slotToElicit === TRAVEL_DATES) {
          setCountiresFinal(false);
        }
        if (messages[messages.length - 1].message === SHOW_FEEDBACK) {
          setCloseTrigger(true);
        }
        if (
          !pegaIntent ||
          lexError ||
          liveAgentJoined ||
          isLastMessageIncludes(WORKING_HOURS_MON_TO_FRI_ENG, messages) ||
          isLastMessageIncludes(WORKING_HOURS_MON_TO_FRI_ESP, messages)
        ) {
          setIsCancelProcess(false);
        }
        if (
          messages[messages.length - 1].message === BACK_TO_CHAT_ENG ||
          messages[messages.length - 1].message === BACK_TO_CHAT_ESP
        ) {
          const defaultContent = englishBot ? WHAT_YOU_NEED_ENG : WHAT_YOU_NEED_ESP;
          liveAgentJoined
            ? handleButtonClickDuringAgentCallMessage(
                {
                  text: defaultContent,
                  value: defaultContent,
                  showYesNo: false,
                  showWrapupStepTwo: false,
                  messageDate: new Date(),
                },
                false
              )
            : handeButtonLinkMessage(
                {
                  text: defaultContent,
                  value: defaultContent,
                  showYesNo: false,
                  showWrapupStepTwo: false,
                  messageDate: new Date(),
                },
                false
              );
        }
        if (
          messages[messages.length - 1].message !==
          (englishBot ? NEED_ANYTHING_ELSE_ENG : NEED_ANYTHING_ELSE_ESP)
        ) {
          setIsValid(true);
          setSendBtnActive(true);
          setErrorMessage('');
        }
        if (
          isLastMessageIncludes('has entered the chat', messages) ||
          isLastMessageIncludes('se ha unido al chat', messages)
        ) {
          setIsFeedbackProcess(true);
        }
        if (
          messages[messages.length - 1].message ===
          (englishBot ? LIVE_AGENT_CHAT_ENDED_ENG : LIVE_AGENT_CHAT_ENDED_ESP)
        ) {
          setAgentFeedbackTrigger(true);
        }
        if (
          messages[messages.length - 1].message ===
          (englishBot ? CHAT_WITH_BOT_ENDED_ENG : CHAT_WITH_BOT_ENDED_ESP)
        ) {
          setIsCancelProcess(false);
        }
        if (
          messages[messages.length - 1].message.includes(SELECT_DIRA_MSG_ENG) ||
          messages[messages.length - 1].message.includes(SELECT_DIRA_MSG_ESP)
        ) {
          setDropDownValues(englishBot ? DIRA_ENG : DIRA_ESP);
        }
        if (
          messages[messages.length - 1].message.includes(SELECT_EIRA_MSG_ENG) ||
          messages[messages.length - 1].message.includes(SELECT_EIRA_MSG_ESP)
        ) {
          setDropDownValues(englishBot ? EIRA_ENG : EIRA_ESP);
        }
        if (
          messages[messages.length - 1].message.includes(EDUCATIVE_TRADITIONAL_IRA_ENG) ||
          messages[messages.length - 1].message.includes(EDUCATIVE_TRADITIONAL_IRA_ESP)
        ) {
          setDropDownValues(iraReasonsList);
        }
      }
    }, [messages, isCancelProcess, isFeedbackProcess, agentFeedbackTrigger, showModal]);
    useEffect(() => {
      const { chatDeviceType, chatDeviceOS, isEncryptionNeeded } = state;

      if (
        // !this.context.currentPage.includes(this.context.previousLocation) &&
        // !liveAgent &&
        sessionID &&
        !isModalOpen
      ) {
        handleClearMessages([]);
        const textMessage = setChatBotIntents(currentPage);

        const botLang = language === 'EN';

        const parameters = getParameters(
          textMessage,
          slotToElicit,
          botLang,
          sessionID,
          chatDeviceType,
          chatDeviceOS,
          SspUserLogin,
          '',
          isEncryptionNeeded,
          customerGuid,
          ''
        );

        axios
          .post(
            process.env.REACT_APP_TOKEN_SERVICE_URL_CHATBOT,
            {},
            {
              headers: {
                Authorization: `Basic ${base64Authorization}`,
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            }
          )
          .then((response) => {
            // this.setState({ postLexToken: response?.data?.access_token });
            handlePostLexToken(response?.data?.access_token);

            axios
              .post(`${process.env.REACT_APP_POST_CALL_API_CHATBOT}`, parameters, {
                headers: {
                  Authorization: `Bearer ${response.data.access_token}`,
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              })
              .then((res) => {
                const { data } = res;
                lexSuccessHandler(data, '');
                if (afterPostLexCall) afterPostLexCall();
              })
              .catch((err) => {
                // const { messages } = state;
                const oldMessages = [...state.messages];
                if (oldMessages.length > 0 && oldMessages[oldMessages.length - 1].message === '') {
                  oldMessages.splice(-1, 1);
                }
                const newMessages = oldMessages;
                setTimeout(() => {
                  handleMessages(newMessages, err);
                }, 100);
              });
          })
          .catch();
      }
      handleLanguage(language);
    }, [currentPage, isModalOpen]);

    useEffect(async () => {
      // i18n.changeLanguage(language ? ENG : ESP);

      // getCountriesList(englishBot, handleCountriesList);
      const textMessage = setChatBotIntents(currentPage);

      onLoadFunction(
        englishBot,
        messages.length,
        btnIntentTrigger,
        handleMessage,
        runningAWSConnect.englishBot,
        textMessage
      );
    }, []);
    useEffect(() => {
      const messageTextarea = document.getElementById('messageText');
      if (messageTextarea) {
        document.getElementById('messageText').focus();
        messageTextarea.selectionStart = messageTextarea.value.length;
        messageTextarea.selectionEnd = messageTextarea.value.length;
      }
    }, [defaultTextContent]);
    useEffect(() => {
      if (slotToElicit === 'knowChatName') {
        const intervalId = window.setInterval(() => {
          if (messages.length > 1) {
            const message = messages[messages.length - 1];
            const diff = moment().diff(moment(message.messageDate), 'seconds');
            if (diff > 9) {
              const defaultContent = englishBot
                ? PLEASE_TELL_YOUR_NAME_ENG
                : PLEASE_TELL_YOUR_NAME_ESP;
              handeButtonLinkMessage(
                { text: defaultContent, value: '', isButtonMessage: false },
                false
              );
            }
          }
        }, 1000);
        return () => {
          clearInterval(intervalId);
        };
      }
    }, [slotToElicit, messages]);

    const onCancelProcessHandler = () => {
      setIsCancelProcess(false);
      setStartDateTravel('');
      setEndDateTravel('');
      const cancelMsg = englishBot ? CANCEL_THIS_PROCESS_ENG : CANCEL_THIS_PROCESS_ESP;
      const modifiedmessage = '<slot>Cancel chat interaction<slot>';
      if (!liveAgentJoined && runningAWSConnect) {
        setTimeout(() => {
          handleMessage({ text: cancelMsg, value: modifiedmessage }, true);
        }, 3000);
      } else {
        handleMessage({ text: cancelMsg, value: modifiedmessage }, true);
      }
      handlerPageIntent();
    };

    const separator = (numb) => {
      if (numb.includes('.')) {
        const str = numb.toString().split('.');
        str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `$${str.join('.')}`;
      }
      const value = Number(numb)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,');
      return `$${value}`;
    };

    const closeEvent = () => {
      if (agentFeedbackTrigger) {
        endChat();
      } else if (liveAgentJoined) {
        setAgentFeedbackTrigger(true);
      } else if (
        !closeTrigger &&
        !lexError &&
        messages.length > 0 &&
        messages[messages.length - 1].message !== SHOW_FEEDBACK
      ) {
        setServiceFormTrigger(false);
        setGeneralFormTrigger(false);
        formInProcessTrigger(false);
        setCloseTrigger(true);
      } else {
        endChat();
      }
      localStorage.removeItem('textContent');
    };
    const endChat = () => {
      setReconnectionStatus();
      messages.push({
        message: englishBot ? END_CHAT_ENG : END_CHAT_ESP,
        messageDate: new Date(),
        isOwn: true,
      });
      endChatApiCall(englishBot, lexUserId, sessionID, userName, messages);
      deleteSessionToLex();
      setIsCancelProcess(false);
      setIsFeedbackProcess(false);

      handlerPageIntent();
      localStorage.removeItem('textContent');
      onCloseClick();
      if ('parentIFrame' in window) {
        window.parentIFrame.getPageInfo((obj) => {
          window.parentIFrame.size(100, 140);
        });
        for (const target of subDomains) {
          window.parent.postMessage('chatbot closed', target);
        }
      }
      minimize();
      setCloseTrigger(false);
      setDefaultTextContent('');
    };
    const endChatFromAgentFeedback = (feedbackInput, feedbackRating) => {
      messages.push({
        message: englishBot ? END_CHAT_ENG : END_CHAT_ESP,
        messageDate: new Date(),
        isOwn: true,
      });
      const feedbackDetails = `${feedbackInput}&&${feedbackRating}`;
      feedbackProcessApiHandler(
        sessionID,
        feedbackDetails,
        awsConnectAgentName,
        customerDetails,
        userName,
        englishBot,
        messages
      );
      deleteSessionToLex();
      setIsCancelProcess(false);
      setIsFeedbackProcess(false);
      setAgentFeedbackTrigger(false);
      handlerPageIntent();
      localStorage.removeItem('textContent');
      onCloseClick();
      if ('parentIFrame' in window) {
        window.parentIFrame.getPageInfo((obj) => {
          window.parentIFrame.size(100, 140);
        });
      }
      minimize();
      setCloseTrigger(false);
      setDefaultTextContent('');
    };
    const handleWrapUpStepOneYes = () => {
      const yesButton = englishBot ? 'Yes' : 'Sí';
      messages.push({ message: yesButton, isButtonMessage: true, isOwn: true });
      let defaultContent = '';
      if (
        isLastMessageIncludes(WILL_CONTACT_YOU_SOON_ENG, messages) ||
        isLastMessageIncludes(WILL_CONTACT_YOU_SOON_ESP, messages)
      ) {
        defaultContent = englishBot ? OFCOURSE_WHAT_YOU_NEED_ENG : WHAT_YOU_NEED_ESP;
      } else {
        defaultContent = englishBot ? WHAT_YOU_NEED_ENG : WHAT_YOU_NEED_ESP;
      }
      handleWrapupMessage(
        { text: defaultContent, value: defaultContent, showYesNo: false, messageDate: new Date() },
        false
      );
    };
    const handleWrapUpStepOneNo = () => {
      handeButtonLinkMessage({ text: 'No', value: '', isButtonMessage: true }, true);
      setCloseTrigger(true);
    };
    const continueChat = () => {
      setCloseTrigger(false);
      setAgentFeedbackTrigger(false);
      spinnerHandler(false);
      const defaultContinueChat = englishBot ? BACK_TO_CHAT_ENG : BACK_TO_CHAT_ESP;
      handeButtonLinkMessage({ text: defaultContinueChat, value: '', isButtonMessage: true }, true);
    };
    const continueChatWithAgent = () => {
      setAgentFeedbackTrigger(false);
      const defaultContinueChat = englishBot ? BACK_TO_CHAT_ENG : BACK_TO_CHAT_ESP;
      handeButtonLinkMessage({ text: defaultContinueChat, value: '', isButtonMessage: true }, true);
    };
    const changeLanguage = () => {
      const defaultContent = englishBot ? WHAT_TO_CHANGE_LANGUAGE_ESP : WHAT_TO_CHANGE_LANGUAGE_ENG;
      handeLanguageChangeMessage(
        { text: defaultContent, value: '', languageChangeYesNo: true },
        false
      );
      setCloseTrigger(false);
      const lang = !englishBot ? 'EN' : 'ES';
      handleLanguage(lang);
    };

    return (
      <div className="main-container">
        <ChatHeader
          englishBot={!!englishBot}
          changeLanguage={changeLanguage}
          minimize={minimize}
          closeEvent={closeEvent}
        />
        <ChatLogo />
        <div id="messageList" className={WrapperCss.message_list_container}>
          <MessageList
            active
            containScrollInSubtree
            id="messageContainer"
            className={
              closeTrigger || agentFeedbackTrigger ? 'messageContainerWrap' : 'messageContainer'
            }
          >
            {!closeTrigger &&
            !agentFeedbackTrigger &&
            !serviceFormTrigger &&
            !generalFormTrigger &&
            !newConciergeCustomer &&
            !existingConciergeCustomer ? (
              <div>
                {messages.length > 0 && (
                  <MessageGroup id="messageGroup" onlyFirstWithMeta>
                    <ChatMessageContainer
                      messages={messages}
                      countriesList={countriesList}
                      lexError={lexError}
                      englishBot={englishBot}
                      startDateTravel={startDateTravel}
                      endDateTravel={endDateTravel}
                      agentTypingFlag={agentTypingFlag}
                      intentLang={intentLang}
                      slotToElicit={slotToElicit}
                      runningAWSConnect={runningAWSConnect}
                      sessionID={sessionID}
                      sendToLex={sendToLex}
                      setMaxLength={setMaxLength}
                      handleMessage={handleMessage}
                      handeLanguageChangeMessage={handeLanguageChangeMessage}
                      ReconnectToAwsConnect={ReconnectToAwsConnect}
                      handleWrapUpStepOneYes={handleWrapUpStepOneYes}
                      setDefaultTextContent={setDefaultTextContent}
                      handleWrapUpStepOneNo={handleWrapUpStepOneNo}
                      handeButtonLinkMessage={handeButtonLinkMessage}
                      handleLanguageChange={handleLanguageChange}
                      setIntentLang={setIntentLang}
                      setWebFormType={setWebFormType}
                      handlePopupRunningCase={handlePopupRunningCase}
                      setShowModal={setShowModal}
                      closeEvent={closeEvent}
                      setIsCancelProcess={setIsCancelProcess}
                      handlerPageIntent={handlerPageIntent}
                      askingForWaitingTime={askingForWaitingTime}
                      setServiceFormTrigger={setServiceFormTrigger}
                      setGeneralFormTrigger={setGeneralFormTrigger}
                      formInProcessTrigger={formInProcessTrigger}
                      spinnerHandler={spinnerHandler}
                      setCountiresFinal={setCountiresFinal}
                      setAutoCompleteFlag={setAutoCompleteFlag}
                      setShowAutoComplete={setShowAutoComplete}
                      setShowRegisterCustomerButton={setShowRegisterCustomerButton}
                      dropDownValues={dropDownValues}
                      handleWrapupMessage={handleWrapupMessage}
                      uploadDocuments={uploadDocuments}
                      attachmentLoader={attachmentLoader}
                      cancelUploadDocuments={cancelUploadDocuments}
                      handleEncryptedDataNeeded={handleEncryptedDataNeeded}
                      setNewConciergeCustomer={setNewConciergeCustomer}
                      setExistingConciergeCustomer={setExistingConciergeCustomer}
                      lexSuccessHandler={lexSuccessHandler}
                      lexFailedHandler={lexFailedHandler}
                      handlePostLexToken={handlePostLexToken}
                      handlePostLexCatch={handlePostLexCatch}
                      SspUserLogin={SspUserLogin}
                      customerGuid={customerGuid}
                      state={state}
                      handleEnglishBot={handleEnglishBot}
                    />
                    {(waitingForLiveAgent || agentTypingFlag || spinnerTrigger) &&
                      !hideOnCustomerType &&
                      !awsConnectError && (
                        <Spinner agentTypingFlag={agentTypingFlag} englishBot={englishBot} />
                      )}
                  </MessageGroup>
                )}{' '}
              </div>
            ) : (
              !lexError &&
              !agentFeedbackTrigger &&
              !serviceFormTrigger &&
              !generalFormTrigger &&
              !newConciergeCustomer &&
              !existingConciergeCustomer &&
              !liveAgentJoined && (
                <StepTwo
                  continueChat={continueChat}
                  wrapEmail={wrapEmail}
                  userName={userName}
                  sessionID={sessionID}
                  englishBot={englishBot}
                  endChat={endChat}
                  sendToLex={sendToLex}
                />
              )
            )}
            {agentFeedbackTrigger && (
              <AgentFeedback
                continueChatWithAgent={continueChatWithAgent}
                continueChat={continueChat}
                liveAgentAvailable={!!liveAgentJoined}
                wrapEmail={wrapEmail}
                userName={userName}
                awsConnectAgentName={awsConnectAgentName}
                sessionID={sessionID}
                englishBot={englishBot}
                endChatFromAgentFeedback={endChatFromAgentFeedback}
              />
            )}

            {generalFormTrigger && !agentFeedbackTrigger && (
              <GeneralInfo
                englishBot={!!englishBot}
                sessionID={sessionID}
                setGeneralFormTrigger={setGeneralFormTrigger}
                setServiceFormTrigger={setServiceFormTrigger}
                formInProcessTrigger={formInProcessTrigger}
                handeButtonLinkMessage={handeButtonLinkMessage}
                setCustomerDetails={setCustomerDetails}
                spinnerHandler={spinnerHandler}
                setShowRegisterCustomerButton={setShowRegisterCustomerButton}
              />
            )}
          </MessageList>
        </div>
        {!closeTrigger &&
          !agentFeedbackTrigger &&
          !serviceFormTrigger &&
          !generalFormTrigger &&
          !newConciergeCustomer &&
          !existingConciergeCustomer && (
            <ValidationProcess
              englishBot={!!englishBot}
              runningAWSConnect={runningAWSConnect}
              showUploadingBar={showUploadingBar}
              liveAgentJoined={liveAgentJoined}
              isFeedbackProcess={isFeedbackProcess}
              setAgentFeedbackTrigger={setAgentFeedbackTrigger}
              onCancelProcessHandler={onCancelProcessHandler}
              isValid={isValid}
              isCancelProcess={isCancelProcess}
              errorMessage={errorMessage}
              waitingForLiveAgent={waitingForLiveAgent}
              setServiceFormTrigger={setServiceFormTrigger}
              formInProcessTrigger={formInProcessTrigger}
              showRegisterCustomerButton={showRegisterCustomerButton}
            />
          )}
      </div>
    );
  }
);
export default Maximized;

Maximized.propTypes = {
  state: PropTypes.object,
  SspUserLogin: PropTypes.string,
  customerGuid: PropTypes.string,
  lexFailedHandler: PropTypes.func,
  afterPostLexCall: PropTypes.func,
  lexSuccessHandler: PropTypes.func,
  handleMessages: PropTypes.func,
  handlePostLexToken: PropTypes.func,
  handleClearMessages: PropTypes.func,
  handleLanguage: PropTypes.func,
  minimize: PropTypes.func,
  messages: PropTypes.array,
  countriesList: PropTypes.array,
  sendToLex: PropTypes.func,
  handleMessage: PropTypes.func,
  handleWrapupMessage: PropTypes.func,
  handleCountriesList: PropTypes.func,
  onCloseClick: PropTypes.func,
  handleLanguageChange: PropTypes.func,
  handeLanguageChangeMessage: PropTypes.func,
  handeButtonLinkMessage: PropTypes.func,
  handleDateMessage: PropTypes.func,
  englishBot: PropTypes.bool,
  lexError: PropTypes.bool,
  defaultTextareaContent: PropTypes.string,
  sessionID: PropTypes.string,
  pegaIntent: PropTypes.bool,
  slotToElicit: PropTypes.string,
  handlerPageIntent: PropTypes.func,
  handlePopupRunningCase: PropTypes.func,
  btnIntentTrigger: PropTypes.string,
  handlerIntentTrigger: PropTypes.func,
  runningAWSConnect: PropTypes.bool,
  sendDocToConnect: PropTypes.func,
  waitingForLiveAgent: PropTypes.bool,
  allowUploadDoc: PropTypes.bool,
  awsConnectError: PropTypes.bool,
  agentTypingFlag: PropTypes.bool,
  awsConnectAgentName: PropTypes.string,
  customerDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ReconnectToAwsConnect: PropTypes.func,
  askingForWaitingTime: PropTypes.func,
  handleButtonClickDuringAgentCallMessage: PropTypes.func,
  liveAgentJoined: PropTypes.bool,
  hideOnCustomerType: PropTypes.bool,
  userEmail: PropTypes.string,
  setUserEmailFromIntent: PropTypes.func,
  verfiedCustomer: PropTypes.bool,
  setVerifedCustomer: PropTypes.func,
  setReconnectionStatus: PropTypes.func,
  addMessages: PropTypes.func,
  deleteSessionToLex: PropTypes.func,
  formInProcessTrigger: PropTypes.func,
  setCustomerDetails: PropTypes.func,
  spinnerTrigger: PropTypes.bool,
  spinnerHandler: PropTypes.func,
  cardValidFlag: PropTypes.bool,
  sendToConnect: PropTypes.func,
  uploadDocuments: PropTypes.func,
  attachmentLoader: PropTypes.bool,
  cancelUploadDocuments: PropTypes.func,
  iraReasonsList: PropTypes.array,
  handleEncryptedDataNeeded: PropTypes.func,
  isModalOpen: PropTypes.bool,
  handlePostLexCatch: PropTypes.func,
  handleEnglishBot: PropTypes.func,
};
