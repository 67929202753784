import { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loader from '../Loader/Loader';
import ThankyouReviewCD from '../LeadGeneration/CertificateOfDeposit/ThankyouReviewCD';
import LoaderCarousel from '../Loader/LoaderCarousel';

const Overlay = lazy(() => import('../Overlay/Overlay'));
const FormHandler = lazy(() => import('../LeadGeneration/FormHandler'));
function Routing() {
  return (
    <div style={{ height: '100%' }}>
      <Suspense
        fallback={
          <div className="loader-style">
            <Loader />{' '}
          </div>
        }
      >
        <Switch>
          <Route exact path="/:region/:lang/:journeyType/:product/:page/" component={FormHandler} />
          <Route
            exact
            path="/:region/:lang/express/:journeyType/:product/:page/"
            component={FormHandler}
          />
          <Route exact path="/:region/:lang/:journeyType/:page/" component={FormHandler} />
          <Route exact path="/:region/:lang/:product/:page/" component={FormHandler} />
          <Route exact path="/:lang/:journeyType/:page/" component={FormHandler} />
          <Route exact path="/:lang/:page/" component={FormHandler} />
          <Route exact path="/" component={Overlay} />
          {/* <Route exact path="/cd-flow" component={CertificateOfDeposit} /> */}
          <Route exact path="/loader" component={LoaderCarousel} />
        </Switch>
      </Suspense>
    </div>
  );
}

export default Routing;
