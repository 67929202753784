/* eslint-disable max-lines */
import data from '../../json-data/address.json';
import {
  ACC_COUNTRY,
  ACC_INFO_CITY,
  ACC_INFO_STATE,
  ACC_INFO_ZIPCODE,
  MANDATORY_FIELD_ERR,
  INVALID_FORMAT_ERR,
  DGI_ADDRESS,
  DGI_EMPLOYMENT_STATUS,
  VALIDATION_BACKSPACE,
} from '../../constants';
import { formatPhoneNumber, formatPhoneNumberForVI } from '../LeadGeneration/StepOne/ContactUtils';

export const returnCityList = (element, trustedContactInformation, personalInformation) => {
  const cityList = [];
  if (element === ACC_INFO_CITY && trustedContactInformation?.country) {
    data[trustedContactInformation.country].forEach((e) => {
      if (trustedContactInformation.state && e.stateInitial === trustedContactInformation?.state) {
        e.city.sort().map((city) => cityList.push(city));
      }
    });
    if (trustedContactInformation?.missingVouchedCity) {
      cityList.push(trustedContactInformation.missingVouchedCity);
    }
  }
  if (trustedContactInformation?.missingAutoCompleteCity) {
    cityList.push(trustedContactInformation.missingAutoCompleteCity);
  }
  return cityList;
};

export const returnCityListEmp = (element, dgiEmploymentStatus, personalInformation) => {
  const cityList = [];
  if (element === ACC_INFO_CITY && dgiEmploymentStatus?.country) {
    data[dgiEmploymentStatus.country].forEach((e) => {
      if (dgiEmploymentStatus.state && e.stateInitial === dgiEmploymentStatus?.state) {
        e.city.sort().map((city) => cityList.push(city));
      }
    });
    if (dgiEmploymentStatus?.missingVouchedCity) {
      cityList.push(dgiEmploymentStatus.missingVouchedCity);
    }
  }
  if (dgiEmploymentStatus?.missingAutoCompleteCity) {
    cityList.push(dgiEmploymentStatus.missingAutoCompleteCity);
  }
  return cityList;
};

export const returnStateList = (element, trustedContactInformation) => {
  const stateListem = [];
  if (element === ACC_INFO_STATE) {
    if (trustedContactInformation?.country) {
      data[trustedContactInformation.country].forEach((e) => {
        const stateDetails = {
          stateInitial: e.stateInitial,
          stateFullName: e.stateFullName,
        };
        stateListem.push(stateDetails);
      });
    }
  }
  return stateListem;
};

export const returnStateListEmp = (element, dgiEmploymentStatus) => {
  const stateListEm = [];
  if (element === ACC_INFO_STATE) {
    if (dgiEmploymentStatus?.country) {
      data[dgiEmploymentStatus.country].forEach((e) => {
        const stateDetails = {
          stateInitial: e.stateInitial,
          stateFullName: e.stateFullName,
        };
        stateListEm.push(stateDetails);
      });
    }
  }
  return stateListEm;
};

export const addAutoCompleteCityToDropdownIfMissing = (
  autoCompleteCountry,
  autoCompleteState,
  autoCompleteCity
) => {
  if (autoCompleteCountry && autoCompleteState) {
    let cityPresent = false;
    data[autoCompleteCountry].forEach((e) => {
      if (e.stateInitial === autoCompleteState) {
        for (const city of e.city) {
          if (city === autoCompleteCity) {
            cityPresent = true;
            break;
          }
        }
      }
    });
    return cityPresent;
  }
  return false;
};
const handleFullName = (e, leadGenerationContext) => {
  const { setTrustedContactInformation, setTrustedContactInformationErrors } =
    leadGenerationContext;
  if (e.target.value.length <= 60) {
    if (e.target.value.match(/^[A-Za-z\u00C0-\u00FF]+( [A-Za-z\u00C0-\u00FF]+)*$/)) {
      setTrustedContactInformation((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      setTrustedContactInformationErrors((prevState) => ({
        ...prevState,
        [e.target.name]: '',
      }));
    } else if (e.target.value === '') {
      setTrustedContactInformation((prevState) => ({
        ...prevState,
        [e.target.name]: '',
      }));
      setTrustedContactInformationErrors((prevState) => ({
        ...prevState,
        [e.target.name]: MANDATORY_FIELD_ERR,
      }));
    } else {
      setTrustedContactInformation((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      setTrustedContactInformationErrors((prevState) => ({
        ...prevState,
        [e.target.name]: INVALID_FORMAT_ERR,
      }));
    }
  }
};
const handleChangePhone = (e, leadGenerationContext) => {
  const {
    setTrustedContactInformation,
    setTrustedContactInformationErrors,
    trustedContactInformationErrors,
    trustedContactInformation,
  } = leadGenerationContext;
  if (e.target.value.match(/^\d/) || e.key === VALIDATION_BACKSPACE) {
    if (e.target.value.length < 8) {
      const formattedPhoneNumber = formatPhoneNumber(e.target.value);
      setTrustedContactInformation((prevState) => ({
        ...prevState,
        [e.target.name]: formattedPhoneNumber,
      }));
      if (e.target.value.length < 7 || formattedPhoneNumber.length < 7) {
        setTrustedContactInformationErrors((prevState) => ({
          ...prevState,
          [e.target.name]: '* Mobile number should have 7 digits',
        }));
      } else {
        setTrustedContactInformationErrors({
          ...trustedContactInformationErrors,
          [e.target.name]: '',
        });
      }
    }
  } else if (e.target.value === '') {
    setTrustedContactInformation({
      ...trustedContactInformation,
      [e.target.name]: '',
    });
    setTrustedContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: MANDATORY_FIELD_ERR,
    }));
  } else {
    setTrustedContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: '* Invalid Format',
    }));
  }
};
const handlePhoneChange = (e, leadGenerationContext) => {
  const {
    setTrustedContactInformation,
    setTrustedContactInformationErrors,
    trustedContactInformation,
  } = leadGenerationContext;
  if (e.target.value?.match(/^\d/) || e.key === VALIDATION_BACKSPACE) {
    if (e.target.value.length < 15) {
      const formattedPhoneNumber = formatPhoneNumberForVI(e.target.value);
      setTrustedContactInformation((prevState) => ({
        ...prevState,
        [e.target.name]: formattedPhoneNumber,
      }));
      setTrustedContactInformationErrors((prevState) => ({
        ...prevState,
        [e.target.name]: '',
      }));
      if (e.target.value.length < 10 || formattedPhoneNumber.length < 10) {
        setTrustedContactInformationErrors((prevState) => ({
          ...prevState,
          [e.target.name]: '* Mobile number should have 10 digits',
        }));
      }
    }
  } else if (e.target.value === '') {
    setTrustedContactInformation({
      ...trustedContactInformation,
      [e.target.name]: '',
    });
    setTrustedContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: MANDATORY_FIELD_ERR,
    }));
  } else {
    setTrustedContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: INVALID_FORMAT_ERR,
    }));
  }
};
const handlePhone = (e, leadGenerationContext) => {
  const { location } = leadGenerationContext;
  if (location !== 'VI') {
    handleChangePhone(e, leadGenerationContext);
  } else {
    if (e.target.value && e.target.value.length === 13) {
      e.target.value = formatPhoneNumberForVI(e.target.value);
    }

    handlePhoneChange(e, leadGenerationContext);
  }
};
const handleCountry = (e, leadGenerationContext) => {
  const { setTrustedContactInformation, setTrustedContactInformationErrors } =
    leadGenerationContext;
  if (e.target.value && e.target.value.length > 0) {
    setTrustedContactInformation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,

      state: e.target.value === 'PR' || e.target.value === 'VI' ? 'N/A' : '',
      city: '',
    }));
    setTrustedContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: '',
      state: '',
      city: '',
    }));
  } else {
    setTrustedContactInformation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value ? e.target.value : '',
      state: '',
      city: '',
    }));
    setTrustedContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: MANDATORY_FIELD_ERR,
      state: '',
      city: '',
    }));
  }
};
const handleAddress = (e, leadGenerationContext) => {
  const { setTrustedContactInformation, setTrustedContactInformationErrors } =
    leadGenerationContext;
  if (e.target.value && e.target.value.length > 0) {
    if (e.target.value.match(/^[A-Za-z0-9'#@%&\\/.\-\s,]+$/) && e.target.value?.length >= 2) {
      setTrustedContactInformation((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      setTrustedContactInformationErrors((prevState) => ({
        ...prevState,
        [e.target.name]: '',
      }));
    } else {
      setTrustedContactInformation((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      setTrustedContactInformationErrors((prevState) => ({
        ...prevState,
        [e.target.name]: '* Invalid address',
      }));
    }
  } else {
    const { value } = e.target;
    setTrustedContactInformation((prevState) => ({
      ...prevState,
      [e.target.name]: value || '',
    }));
    setTrustedContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: '',
    }));
  }
};
const handleState = (e, leadGenerationContext) => {
  const { setTrustedContactInformation, setTrustedContactInformationErrors } =
    leadGenerationContext;
  if (e.target.value && e.target.value.length > 0) {
    setTrustedContactInformation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setTrustedContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: '',
    }));
  } else {
    setTrustedContactInformation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value ? e.target.value : '',
    }));
    setTrustedContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: MANDATORY_FIELD_ERR,
    }));
  }
};
const handleAreaCode = (e, leadGenerationContext) => {
  const {
    trustedContactInformation,
    setTrustedContactInformation,
    setTrustedContactInformationErrors,
  } = leadGenerationContext;
  if (e.target.value.match(/^\d+$/) || e.key === VALIDATION_BACKSPACE) {
    setTrustedContactInformation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setTrustedContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: '',
    }));
    if (e.target.value.length < 3) {
      setTrustedContactInformationErrors((prevState) => ({
        ...prevState,
        [e.target.name]: '* Area Code should have 3 digits',
      }));
    }
    if (e.target.value.length === 3 && trustedContactInformation.phone?.length === 8) {
      setTrustedContactInformationErrors((prevState) => ({
        ...prevState,
        [e.target.name]: '',
      }));
      setTrustedContactInformationErrors((prevState) => ({ ...prevState, phone: '' }));
    }
  } else if (e.target.value === '') {
    setTrustedContactInformation({
      ...trustedContactInformation,
      [e.target.name]: '',
    });
    setTrustedContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: '* Area Code is Mandatory',
    }));
  } else {
    setTrustedContactInformation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setTrustedContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: INVALID_FORMAT_ERR,
    }));
  }
};
const handleZipcode = (e, leadGenerationContext) => {
  const {
    setTrustedContactInformation,
    setTrustedContactInformationErrors,
    trustedContactInformation,
  } = leadGenerationContext;
  if (e.target?.value?.match(/^\d+$/) || e.key === VALIDATION_BACKSPACE) {
    setTrustedContactInformation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setTrustedContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: '',
    }));

    if (e.target.value.length < 5) {
      setTrustedContactInformationErrors((prevState) => ({
        ...prevState,
        [e.target.name]: '* Zipcode should have 5 digits',
      }));
    } else if (e.target.value?.match(/^0*$/g)) {
      setTrustedContactInformationErrors((prevState) => ({
        ...prevState,
        [e.target.name]: '* Please enter a valid zipcode',
      }));
    } else {
      setTrustedContactInformationErrors((prevState) => ({
        ...prevState,
        [e.target.name]: '',
      }));
    }
  } else if (e.target.value === '') {
    setTrustedContactInformation({
      ...trustedContactInformation,
      [e.target.name]: '',
    });
    setTrustedContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: MANDATORY_FIELD_ERR,
    }));
  } else {
    setTrustedContactInformation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setTrustedContactInformationErrors((prevState) => ({
      ...prevState,
      [e.target.name]: INVALID_FORMAT_ERR,
    }));
  }
};
const handleYear = (e, leadGenerationContext) => {
  const {
    trustedContactInformation,
    setTrustedContactInformation,
    setTrustedContactInformationErrors,
  } = leadGenerationContext;
  if (e.target.value?.length !== 0) {
    setTrustedContactInformation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      dob: `${prevState?.dob_day} ${prevState?.dob_month} ${e.target.value}`,
    }));
    if (
      trustedContactInformation?.dob_day !== 'DD' &&
      trustedContactInformation?.dob_month !== 'MMM' &&
      e.target.value !== 'YYYY'
    ) {
      setTrustedContactInformationErrors((prevState) => ({
        ...prevState,
        dob: '',
      }));
    }
  }
};
const handleMonth = (e, leadGenerationContext) => {
  const {
    trustedContactInformation,
    setTrustedContactInformation,
    setTrustedContactInformationErrors,
  } = leadGenerationContext;
  if (e.target.value?.length !== 0) {
    setTrustedContactInformation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      dob: `${prevState?.dob_day} ${e.target.value} ${prevState?.dob_year}`,
    }));
    if (
      trustedContactInformation?.dob_day !== 'DD' &&
      e.target.value !== 'MMM' &&
      trustedContactInformation?.dob_year !== 'YYYY'
    ) {
      setTrustedContactInformationErrors((prevState) => ({
        ...prevState,
        dob: '',
      }));
    }
  }
};
export const handleChange = (e, leadGenerationContext) => {
  const {
    setTrustedContactInformation,
    setTrustedContactInformationErrors,
    setIsTrustInfoDirty,
    setIsFundingScreenDirty,
    setIsFinanceInfoDirty,
    setIsEdeliveryScreenDirty,
  } = leadGenerationContext;

  setIsTrustInfoDirty(true);
  setIsFinanceInfoDirty(true);
  setIsFundingScreenDirty(true);
  setIsEdeliveryScreenDirty(true);
  switch (e.target.name) {
    case 'fullName':
      handleFullName(e, leadGenerationContext);
      break;
    case 'areaCode':
      handleAreaCode(e, leadGenerationContext);
      break;

    case 'phone':
      handlePhone(e, leadGenerationContext);
      break;
    case ACC_COUNTRY:
      handleCountry(e, leadGenerationContext);
      break;

    case DGI_ADDRESS:
      handleAddress(e, leadGenerationContext);
      break;

    case ACC_INFO_STATE:
      handleState(e, leadGenerationContext);
      break;

    case ACC_INFO_CITY:
      if (e.target.value && e.target.value.length > 0) {
        setTrustedContactInformation((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
        setTrustedContactInformationErrors((prevState) => ({ ...prevState, [e.target.name]: '' }));
      } else {
        setTrustedContactInformation((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value ? e.target.value : '',
        }));
        setTrustedContactInformationErrors((prevState) => ({
          ...prevState,
          [e.target.name]: MANDATORY_FIELD_ERR,
        }));
      }
      break;

    case ACC_INFO_ZIPCODE:
      handleZipcode(e, leadGenerationContext);
      break;

    case 'dob_day':
      if (e.target.value?.length !== 0) {
        setTrustedContactInformation((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
          dob: `${e.target.value} ${prevState?.dob_month} ${prevState?.dob_year}`,
        }));
        setTrustedContactInformationErrors((prevState) => ({
          ...prevState,
          dob: '',
        }));
      }
      break;

    case 'dob_month':
      handleMonth(e, leadGenerationContext);
      break;

    case 'dob_year':
      handleYear(e, leadGenerationContext);
      break;

    default:
      break;
  }
};
const handleDetailsCountry = (e, leadGenerationContext) => {
  const { setDgiEmploymentStatus, setDgiEmploymentStatusErrors } = leadGenerationContext;
  if (e.target.value && e.target.value.length > 0) {
    setDgiEmploymentStatus((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      city: '',
      state: e.target.value === 'PR' || e.target.value === 'VI' ? 'N/A' : '',
    }));
    setDgiEmploymentStatusErrors((prevState) => ({
      ...prevState,
      [e.target.name]: '',
      city: '',
      state: '',
    }));
  } else {
    setDgiEmploymentStatus((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value ? e.target.value : '',
      city: '',
      state: '',
    }));
    setDgiEmploymentStatusErrors((prevState) => ({
      ...prevState,
      [e.target.name]: MANDATORY_FIELD_ERR,
      city: '',
      state: '',
    }));
  }
};
const handleDetailsState = (e, leadGenerationContext) => {
  const { setDgiEmploymentStatus, setDgiEmploymentStatusErrors } = leadGenerationContext;
  if (e.target.value && e.target.value.length > 0) {
    setDgiEmploymentStatus((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setDgiEmploymentStatusErrors((prevState) => ({
      ...prevState,
      [e.target.name]: '',
    }));
  } else {
    setDgiEmploymentStatus((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value ? e.target.value : '',
    }));
    setDgiEmploymentStatusErrors((prevState) => ({
      ...prevState,
      [e.target.name]: MANDATORY_FIELD_ERR,
    }));
  }
};
const handleDetailsZipcode = (e, leadGenerationContext) => {
  const { setDgiEmploymentStatus, setDgiEmploymentStatusErrors } = leadGenerationContext;
  if (e.target?.value?.match(/^\d+$/) || e.key === VALIDATION_BACKSPACE) {
    setDgiEmploymentStatus((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setDgiEmploymentStatusErrors((prevState) => ({
      ...prevState,
      [e.target.name]: '',
    }));

    if (e.target.value.length < 5) {
      setDgiEmploymentStatusErrors((prevState) => ({
        ...prevState,
        [e.target.name]: '* Zipcode should have 5 digits',
      }));
    } else if (e.target.value?.match(/^0*$/g)) {
      setDgiEmploymentStatusErrors((prevState) => ({
        ...prevState,
        [e.target.name]: '* Please enter a valid zipcode',
      }));
    } else {
      setDgiEmploymentStatusErrors((prevState) => ({
        ...prevState,
        [e.target.name]: '',
      }));
    }
  } else if (e.target.value === '') {
    setDgiEmploymentStatus((prevState) => ({ ...prevState, [e.target.name]: '' }));
    setDgiEmploymentStatusErrors((prevState) => ({
      ...prevState,
      [e.target.name]: MANDATORY_FIELD_ERR,
    }));
  } else {
    setDgiEmploymentStatus((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setDgiEmploymentStatusErrors((prevState) => ({
      ...prevState,
      [e.target.name]: INVALID_FORMAT_ERR,
    }));
  }
};

const handleDetailsStatus = (e, leadGenerationContext) => {
  const {
    setDgiEmploymentStatus,
    setDgiEmploymentStatusErrors,
    dgiEmploymentStatus,
    dgiEmploymentStatusErrors,
  } = leadGenerationContext;
  if (e.target.value && e.target.value.length > 0) {
    if (e.target.value === 'Self Employed') {
      leadGenerationContext.setDgiEmploymentStatus({
        employer: '',
        occupation: '',
        years: '',
      });
      leadGenerationContext.setDgiEmploymentStatusErrors({
        employer: '',
        occupation: '',
        years: '',
      });
    }
    if (e.target.value === 'Retired') {
      leadGenerationContext.setDgiEmploymentStatus({
        previousEmployer: '',
        occupation: '',
      });
      leadGenerationContext.setDgiEmploymentStatusErrors({
        previousEmployer: '',
        occupation: '',
      });
    }
    if (e.target.value === 'Employed') {
      leadGenerationContext.setDgiEmploymentStatus({
        occupation: '',
        empStatus: '',

        years: '',
        employer: '',
      });
      leadGenerationContext.setDgiEmploymentStatusErrors({
        occupation: '',
        empStatus: '',

        years: '',
        employer: '',
      });
    }
    if (e.target.value === 'Not Employed') {
      leadGenerationContext.setDgiEmploymentStatus({
        reason: '',
      });
      leadGenerationContext.setDgiEmploymentStatusErrors({
        reason: '',
      });
    }

    setDgiEmploymentStatus({
      ...dgiEmploymentStatus,
      [e.target.name]: e.target.value,
    });

    setDgiEmploymentStatusErrors({});
  } else {
    setDgiEmploymentStatus({
      ...dgiEmploymentStatus,
      [e.target.name]: e.target.value ? e.target.value : '',
    });
    setDgiEmploymentStatusErrors({
      ...dgiEmploymentStatusErrors,
      [e.target.name]: MANDATORY_FIELD_ERR,
    });
  }
};

const handleCityAndOccupation = (e, leadGenerationContext) => {
  const { setDgiEmploymentStatus, setDgiEmploymentStatusErrors } = leadGenerationContext;
  if (e.target.value && e.target.value.length > 0) {
    setDgiEmploymentStatus((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setDgiEmploymentStatusErrors((prevState) => ({ ...prevState, [e.target.name]: '' }));
  } else {
    setDgiEmploymentStatus((prevState) => ({
      ...prevState,

      [e.target.name]: e.target.value ? e.target.value : '',
    }));
    setDgiEmploymentStatusErrors((prevState) => ({
      ...prevState,
      [e.target.name]: MANDATORY_FIELD_ERR,
    }));
  }
};
const handleDetailsYear = (e, leadGenerationContext) => {
  const { setDgiEmploymentStatus, setDgiEmploymentStatusErrors } = leadGenerationContext;
  if (e.target.value && e.target.value.length > 0) {
    if (e.target.value.match(/^\d+$/)) {
      setDgiEmploymentStatus((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      setDgiEmploymentStatusErrors((prevState) => ({
        ...prevState,
        [e.target.name]: '',
      }));
    } else {
      setDgiEmploymentStatusErrors((prevState) => ({
        ...prevState,
        [e.target.name]: INVALID_FORMAT_ERR,
      }));
    }
  } else {
    setDgiEmploymentStatus((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value ? e.target.value : '',
    }));
    setDgiEmploymentStatusErrors((prevState) => ({
      ...prevState,
      [e.target.name]: MANDATORY_FIELD_ERR,
    }));
  }
};

export const handleChangeDetails = (e, leadGenerationContext) => {
  const {
    setIsTrustInfoDirty,
    setIsFundingScreenDirty,
    setIsEdeliveryScreenDirty,
    setIsFinanceInfoDirty,
  } = leadGenerationContext;

  setIsTrustInfoDirty(true);
  setIsFinanceInfoDirty(true);
  setIsFundingScreenDirty(true);
  setIsEdeliveryScreenDirty(true);
  switch (e.target.name) {
    case DGI_EMPLOYMENT_STATUS:
      handleDetailsStatus(e, leadGenerationContext);
      break;

    case 'reason':
    case 'employer':
      leadGenerationContext.setDgiEmploymentStatus({
        ...leadGenerationContext.dgiEmploymentStatus,
        [e.target.name]: e.target.value,
      });
      if (e.target.name === 'employer') {
        if (e.target.value.length >= 2 && e.target.value.match(/^[A-Za-z0-9@&_.\-\s,()]*$/)) {
          leadGenerationContext.setDgiEmploymentStatusErrors({
            ...leadGenerationContext.dgiEmploymentStatusErrors,
            [e.target.name]: '',
          });
        } else if (e.target.value.length === 0) {
          leadGenerationContext.setDgiEmploymentStatusErrors({
            ...leadGenerationContext.dgiEmploymentStatusErrors,
            [e.target.name]: MANDATORY_FIELD_ERR,
          });
        } else {
          leadGenerationContext.setDgiEmploymentStatusErrors({
            ...leadGenerationContext.dgiEmploymentStatusErrors,
            [e.target.name]: '* Invalid Employer Name',
          });
        }
      } else if (e.target.value.length === 0) {
        leadGenerationContext.setDgiEmploymentStatusErrors({
          ...leadGenerationContext.dgiEmploymentStatusErrors,
          [e.target.name]: MANDATORY_FIELD_ERR,
        });
      } else {
        leadGenerationContext.setDgiEmploymentStatusErrors({
          ...leadGenerationContext.dgiEmploymentStatusErrors,
          [e.target.name]: '',
        });
      }
      break;

    case 'occupation':
      handleCityAndOccupation(e, leadGenerationContext);
      break;

    case 'years':
      handleDetailsYear(e, leadGenerationContext);
      break;

    case ACC_COUNTRY:
      handleDetailsCountry(e, leadGenerationContext);
      break;

    case ACC_INFO_STATE:
      handleDetailsState(e, leadGenerationContext);
      break;

    case ACC_INFO_CITY:
      handleCityAndOccupation(e, leadGenerationContext);
      break;

    case ACC_INFO_ZIPCODE:
      handleDetailsZipcode(e, leadGenerationContext);
      break;

    default:
      break;
  }
};
export const handleBlurDetails = (e, leadGenerationContext) => {
  const {
    dgiEmploymentStatus,
    setDgiEmploymentStatus,
    setIsTrustInfoDirty,
    setIsFundingScreenDirty,
    setIsEdeliveryScreenDirty,
    setIsFinanceInfoDirty,
  } = leadGenerationContext;

  setIsTrustInfoDirty(true);
  setIsFinanceInfoDirty(true);
  setIsFundingScreenDirty(true);
  setIsEdeliveryScreenDirty(true);

  if (e.target.name === 'years') {
    if (dgiEmploymentStatus.years?.length === 1) {
      if (dgiEmploymentStatus.years >= 0 && dgiEmploymentStatus.years <= 9) {
        setDgiEmploymentStatus((prevState) => ({
          ...prevState,
          years: `0${dgiEmploymentStatus.years}`,
        }));
      } else {
        setDgiEmploymentStatus((prevState) => ({
          ...prevState,
          years: dgiEmploymentStatus.years,
        }));
      }
    }
  }
};
