import { makeStyles } from '@material-ui/core';

export const LoaderCarouselStyles = () =>
  makeStyles(
    (theme) => ({
      loaderBox: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      textBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px 0 67px 0',
        flexDirection: 'column',
      },
      headingText: {
        font: 'normal normal 700 48px/50px Arial',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'none',
      },

      subTextBox: {
        [theme.breakpoints.down('601')]: {
          margin: '0 25px',
        },
      },
      subText: {
        font: 'normal normal 400 20px/23px Arial',
        color: ' #444444',
        textAlign: 'center !important',
      },
      carouselBox: { display: 'flex', justifyContent: 'center', alignItems: 'center' },

      contentBox: { backgroundColor: '#FCF1E8' },
      contentTextBox: {
        padding: '32px 0 23px 0',
        [theme.breakpoints.down('601')]: {
          padding: '32px 0 10px 0',
        },
      },
      textImage1: {
        font: 'normal 700 40px/47px Arial',
        color: '#E37617',
        textAlign: 'center',
        maxWidth: '100%',
        margin: '12px auto',
        [theme.breakpoints.down('601')]: {
          font: 'normal 700 30px/34px Arial',
          maxWidth: 'none',
        },
      },
      textImage2: {
        font: 'normal 500 24px/28px Arial',
        color: '#444',
        textAlign: 'center',
        marginTop: '17px',
      },

      iconsBox: {
        backgroundColor: 'white',
        margin: '20px 82px 0 82px',
        padding: '20px',
        [theme.breakpoints.down('601')]: {
          margin: '20px 10px 0 10px',
        },
      },
      iconsContainer: {
        justifyContent: 'space-between',
        [theme.breakpoints.down('960')]: {
          justifyContent: 'start',
        },
      },
      icon: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      },
      imgSize: {
        height: '54px',
        width: '54px',
      },
      ATMiconName: {
        color: '#444',
        font: 'normal 700 16px/19px TTNormsProRegular',
        textAlign: 'center',
        width: '196px',
        // fontSize: '16px',
        // fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
        [theme.breakpoints.down('1096')]: {
          width: 'auto',
          // maxWidth: '196px',
        },
        [theme.breakpoints.down('601')]: {
          fontSize: '12px',
        },
      },
      iconName: {
        color: '#444',
        font: 'normal 700 16px/19px TTNormsProRegular',
        textAlign: 'center',
        // fontSize: '16px',
        // fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
        [theme.breakpoints.down('601')]: {
          fontSize: '12px',
        },
      },
      secondImageTextBox: {
        // margin: 'auto',
        padding: '30px 45px',
        height: '276px',

        [theme.breakpoints.down('960')]: {
          padding: '45px 10px',
          height: '285px',
        },
        [theme.breakpoints.down('335')]: {
          padding: '19px 10px',
          // height: '285px',
        },
      },

      slider2: {
        position: 'relative',
        height: '300px',
        overflow: 'hidden',
        maxWidth: '75%',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('960')]: {
          // height: '230px',
        },
        [theme.breakpoints.down('600')]: {
          // height: '200px',
          margin: '0 20px',
          maxWidth: '100%',
        },
        [theme.breakpoints.down('373')]: {
          // height: '256px',
          margin: '0 20px',
          maxWidth: '100%',
        },
      },

      slider1: {
        position: 'relative',
        height: '300px',
        overflow: 'hidden',
        maxWidth: '75%',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('1310')]: {
          height: '350px',
        },
        [theme.breakpoints.down('1280')]: {
          height: '420px',
        },
        [theme.breakpoints.down('1064')]: {
          height: '430px',
        },
        [theme.breakpoints.down('916')]: {
          height: '425px',
        },
        [theme.breakpoints.down('870')]: {
          height: '470px',
        },
        [theme.breakpoints.down('734')]: {
          height: '488px',
        },
        [theme.breakpoints.down('671')]: {
          height: '532px',
        },
        [theme.breakpoints.down('635')]: {
          height: '562px',
        },
        [theme.breakpoints.down('601')]: {
          height: '353px',
          maxWidth: '100%',
          margin: '0 20px',
        },
        [theme.breakpoints.down('542')]: {
          height: '353px',
          maxWidth: '100%',
          margin: '0 20px',
        },
        [theme.breakpoints.down('398')]: {
          height: '385px',
          maxWidth: '100%',
          margin: '0 20px',
        },
      },

      carousalImage: { display: 'flex', alignItems: 'center' },

      sliderItem: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        opacity: 0,
        transition: 'opacity 1s ease-in-out',
      },

      sliderItemActive: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        // opacity: 0,
        transition: 'opacity 1s ease-in-out',
        opacity: 1,
      },

      sliderControls: {
        marginTop: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
        left: 0,
        right: 0,
        color: '#fff',
      },
      sliderIndicatorItem: {
        borderRadius: '50%',
        padding: '0 8px',
        cursor: 'pointer',
        margin: '0 5px',
        backgroundColor: 'lightgrey',
      },
      sliderIndicatorItemActive: {
        borderRadius: '20%/50%',
        padding: '0 16px',
        cursor: 'pointer',
        margin: '0 5px',
        backgroundColor: '#e37617',
      },

      itemSlider: {
        color: '#fff',
      },
    }),
    { index: 1 }
  );
