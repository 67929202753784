import axios from 'axios';

export const getAccessToken = async () => {
  const tokenResponse = await axios.post(
    process.env.REACT_APP_TOKEN_SERVICE_URL,
    { clientId: process.env.REACT_APP_CLIENT_ID },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );
  return tokenResponse?.data?.token;
};
export const sspLogoutCall = async (token, customerNumber, language, uuid) => {
  let response = null;
  try {
    response = await axios.post(
      process.env.REACT_APP_EXPRESS_SSP_SESSION_LOGOUT,
      {
        customerNumber,
        language: language === 'EN' ? 'eng' : 'esp',
        uuid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (e) {
    return e.response;
  }
  return response;
};
