export const businessMapping = (productName) => {
  const map = new Map();
  const businessProdName = (prodName) => {
    if (productName?.toLowerCase() === prodName?.toLowerCase()) {
      map.set(productName, prodName);
    }
  };
  const businessProdName1 = (prodName, prodNameSet) => {
    if (productName?.toLowerCase() === prodName?.toLowerCase()) {
      map.set(productName, prodNameSet);
    }
  };
  businessProdName('Ahorro Comercial');
  businessProdName('CD Comercial');
  businessProdName('SIN FINES DE LUCRO');
  businessProdName('ESCROW ACCOUNT');
  businessProdName('My Biz Account');
  businessProdName1('Commercial CD', 'CD Comercial');
  businessProdName1('NON-PROFIT', 'SIN FINES DE LUCRO');
  businessProdName1('Commercial Loan', 'Prestamo Comercial');
  businessProdName1('Préstamo Comercial', 'Prestamo Comercial');
  businessProdName('Prestamo Comercial');
  businessProdName1('Commercial Credit Card', 'Tarjeta de Credito Comercial');
  businessProdName1('Tarjeta de Crédito Comercial', 'Tarjeta de Credito Comercial');
  businessProdName('Tarjeta de Credito Comercial');
  businessProdName1('Commercial Line of Credit', 'Linea de Credito Comercial');
  businessProdName1('Línea de Crédito Comercial', 'Linea de Credito Comercial');
  businessProdName('Linea de Credito Comercial');

  return map.get(productName);
};
