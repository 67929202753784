import moment from 'moment-timezone';
import {
  OccupationListEN,
  OccupationListES,
} from '../components/LeadGeneration/Employment/OccupationList';
import { businessMapping } from './businessMapping';
import { dgiMapping } from './dgiMapping';
import { productMapping } from './productMapping';
import { productTypeMapping } from './productTypeMapping';
import {
  industryTypeListEN,
  industryTypeListES,
} from '../components/CommercialDeposit/IndustryTypeList';
import { ELITE_ACCOUNT_EN, ELITE_ACCOUNT_ES } from '../constants';

const getProductName = (prodName) => {
  let name;
  if (dgiMapping(prodName)) {
    name = dgiMapping(prodName);
  } else if (businessMapping(prodName)) {
    name = businessMapping(prodName);
  } else {
    name = productMapping(prodName);
  }
  return name;
};
export const createFormData = (rules, data) => ({
  ruleTypes: rules,
  applicationData: {
    ApplicationID: data.flowType === 'invitation-form' ? data.applicationID : null,
    TemplateVersion: '2021-08-05',
    Source: {
      Language: data.language,
      Location: data.location === 'VI' ? 'VI' : 'PR',
      UtmSource: null,
      UtmMedium: null,
      UtmCampaign: null,
      UtmContent: null,
      UtmTerm: null,
      PointOfOrigin: null,
      RedirectionId: null,
      ChannelAddress: {
        IPAddress: null,
        DeviceId: null,
      },
    },
    Products: [
      {
        ProductType:
          data.flowType === 'invitation-form'
            ? 'CommercialInvitation'
            : productTypeMapping(data.productType),
        ProductName:
          data.flowType === 'invitation-form'
            ? 'SecondaryApplicant'
            : getProductName(data.productName),
        ProductCode: null,
        LevelOfInterest: null,
        Questions: null,
        Category: 'Primary',
      },
      ...(data.productName === ELITE_ACCOUNT_EN || data.productName === ELITE_ACCOUNT_ES
        ? [
            {
              ProductType:
                data.productName === ELITE_ACCOUNT_EN || data.productName === ELITE_ACCOUNT_ES
                  ? 'Savings Account'
                  : data.flowType === 'invitation-form'
                  ? 'CommercialInvitation'
                  : productTypeMapping(data.productType),
              ProductName:
                data.flowType === 'invitation-form'
                  ? 'SecondaryApplicant'
                  : getProductName(data.productName),
              ProductCode: null,
              LevelOfInterest: null,
              Questions: null,
              Category: 'Secondary',
            },
          ]
        : []),
    ],
    Business: {
      Type: data.flowType === 'invitation-form' ? null : data.businessType || null,
    },
    Applicants: [
      {
        ApplicantID: data.flowType === 'invitation-form' ? data.applicantID : null,
        ApplicantCategory: data.flowType === 'invitation-form' ? 'DigitalApplicant' : 'Primary',
        ApplicantType: null,
        CustId: null,
        CustTaxId: null,
        Gender: null,
        CustName: {
          FrstName: null,
          MdleName: null,
          LstName: null,
          SecondLstName: null,
        },
        Contacts: [
          {
            ContactType: null,
            ContactValue: null,
            Validation: {
              ValidatedBy: null,
              Status: null,
              ResultCodes: null,
              Message: null,
              Timestamp: null,
            },
          },
          {
            ContactType: null,
            ContactValue: null,
          },
          {
            ContactType: null,
            ContactValue: null,
          },
        ],
        Addresses: [
          {
            AddrType: null,
            LineOne: null,
            LineTwo: null,
            AddrCity: null,
            AddrState: null,
            AddrCounty: null,
            AddrCountry: null,
            ZipCode: null,
            UtilityBill: null,
            Validation: {
              ValidatedBy: null,
              Status: null,
              ResultCodes: null,
              Message: null,
              Timestamp: null,
            },
          },
          {
            AddrType: null,
            LineOne: null,
            LineTwo: null,
            AddrCity: null,
            AddrState: null,
            AddrCounty: null,
            AddrCountry: null,
            ZipCode: null,
            Validation: {
              ValidatedBy: null,
              Status: null,
              ResultCodes: null,
              Message: null,
              Timestamp: null,
            },
          },
        ],
        Identifications: [
          {
            IdenType: null,
            IdNumber: null,
            IdenValidity: null,
            IdenDOB: null,
            IdenGender: null,
            IdenValidated: null,
            IdenDataModified: null,
          },
        ],
        Incomedetails: {
          AnnualIncome: null,
          IncomeType: null,
          Employer: null,
        },
        Consents: null,
        Documents: null,
        AdditionalInfo: {
          IsMailHomeAddrSame: null,
          Vouched: {
            JobId: null,
            Token: null,
          },
        },
      },
    ],
    Processing: {
      CaseReference: [
        {
          CaseRefId: null,
          SubStatus: null,
          CaseStatus: null,
          ProductType: null,
          CaseRefHandle: null,
        },
      ],
    },
    AdditionalInfo: {
      IsMailHomeAddrSame: null,
    },
  },
});

export const formatSSNToNumbers = (ssn) => ssn?.toString().replace(/\D/g, '');

let consentStatus;
export const createConsent = (consent, consentValues, consentTimeStamps) => {
  const consentStatusCheck = () => {
    if (consentValues[consent.name.split('.')[0]] || consent.type === 'Info') {
      consentStatus = 'opt-in';
    } else {
      consentStatus = 'opt-out';
    }
    return consentStatus;
  };
  let consentContent = null;
  consentContent = {
    Name: consent.name,
    ConsentType: consent.type,
    Source: 'Oriental Website',
    Status: consentStatusCheck(),
    ContentType: consent.contentType,
    Location: consent.location,
    LocationType: consent.locationType,
    CapturedTimestamp:
      consent.type === 'Info'
        ? moment()
            .tz((moment().toDate(), moment.tz.guess(true)))
            .utc()
            .format()
        : consentTimeStamps[consent.name.split('.')[0]],
  };
  return consentContent;
};

export const createLinkedConsent = (linkedConsents) => {
  const submitLinkedConsents = [];
  for (const element of linkedConsents) {
    const linkedConsent = element;
    const submitLinkedConsent = {
      Name: linkedConsent.name,
      ContentType: linkedConsent.contentType,
      Location: linkedConsent.location,
      ...(linkedConsent.s3Location ? { S3Location: linkedConsent.s3Location } : {}),
      LocationType: linkedConsent.locationType,
      ViewedTimestamp: moment()
        .tz((moment().toDate(), moment.tz.guess(true)))
        .utc()
        .format(),
    };
    submitLinkedConsents.push(submitLinkedConsent);
  }
  return submitLinkedConsents;
};

export const buildConsentInfo = (values) => {
  const consentContent = [];
  const { consents } = values;
  const { consentValues } = values;
  const { consentTimeStamps } = values;
  for (const element of consents) {
    const consentSubmitContent = createConsent(element, consentValues, consentTimeStamps);
    if (element?.linkedConsents) {
      const linkedConsents = createLinkedConsent(element.linkedConsents);
      consentSubmitContent.LinkedConsents = linkedConsents;
    }
    consentContent.push(consentSubmitContent);
  }
  return consentContent;
};

export const createDocumentInfo = (docId, docType) => ({
  DocType: docType,
  DocId: docId,
});

export const createDocumentProofsArray = (docId, docType) => {
  const docProofs = [];
  if (docId !== null && docId !== undefined && docId) {
    const proof = createDocumentInfo(docId, docType);
    docProofs.push(proof);
  }
  return docProofs;
};
const getFormDataDetails = (component, formData) => {
  const formData1 = formData;
  if (component.subComponents) {
    formData1[component.label] = {};
    formData1[component.label].Selected = '';
    component.subComponents.forEach((subComponent) => {
      formData1[component.label][subComponent.label] = '';
    });
  } else if (component.label === 'Approximate Amount') {
    formData1.ApproximateAmount = '';
  } else if (component.label === 'Withdraw $') {
    formData1.MakeChecksWithdrawDollar = '';
  } else {
    formData1[component.label] = '';
  }
  return formData1;
};
export const getQuestionFormData = (questions) => {
  let formData = {};
  questions.forEach((question) => {
    if (question.type === 'Form') {
      if (question.next) {
        question.next.forEach((item) => {
          if (item.components) {
            item.components.forEach((component) => {
              if (component.type !== 'image') {
                formData = getFormDataDetails(component, formData);
              }
            });
          }
        });
      }
    }
  });
  return formData;
};
const sampleFunction = (formData, actFormData, props, formAnswer) => {
  const actFormData1 = actFormData;
  for (const prop in formData[props[1]]) {
    if (prop === 'Selected') {
      actFormData1[props[1]].Selected = formAnswer[`${props[0]}_${props[1]}`];
    } else if (props.length > 2) {
      if (prop === props[2]) {
        actFormData1[props[1]][props[2]] = formAnswer[`${props[0]}_${props[1]}_${props[2]}`];
      }
    }
  }
  return actFormData1;
};
export const buildFormData = (formAnswer, formData, questionName) => {
  let actFormData = {};
  for (const formProperty in formAnswer) {
    if (formProperty) {
      const props = formProperty.split('_');
      if (props[0] === questionName) {
        if (typeof formData[props[1]] === 'object') {
          actFormData[props[1]] = {
            ...formData[props[1]],
            ...actFormData[props[1]],
          };
          actFormData = sampleFunction(formData, actFormData, props, formAnswer);
        } else if (props[1] === 'Approximate Amount') {
          actFormData.ApproximateAmount = formAnswer[formProperty];
        } else if (props[1] === 'Withdraw $') {
          actFormData.MakeChecksWithdrawDollar = formAnswer[formProperty];
        } else {
          actFormData[props[1]] = formAnswer[formProperty];
        }
      }
    }
  }
  return actFormData;
};
const getAnswerType = (question) => {
  let answerType1;
  if (question.type !== 'IRA') {
    answerType1 = question.widget?.type ?? 'boolean';
  } else {
    answerType1 = 'String';
  }
  return answerType1;
};
const typeForm = (question, formAnswer, formData) => {
  let qAndA1;
  if (question.type === 'Form') {
    qAndA1 = buildFormData(formAnswer, { ...formData }, question.name);
  }
  return qAndA1;
};
const typeKnockout = (question, act22DocID) => {
  let qAndA1;
  if (question.name === 'Act22' && question.type === 'Knockout') {
    qAndA1 = createDocumentProofsArray(act22DocID, 'Act 22 Certificate');
  }
  return qAndA1;
};
const setInternationalCountryList = (question, data, qAndAFormData) => {
  const qAndA1FormData = { ...qAndAFormData };
  if (
    question.name === 'InternationalWireTransfer' &&
    businessMapping(data.productName) &&
    (productTypeMapping(data.productType) === 'Checking Account' ||
      productTypeMapping(data.productType) === 'Savings Account')
  ) {
    let countryCodes = [];
    if (data.countries.length > 0) {
      countryCodes = data.countries.map((country) => country.code);
      qAndA1FormData.TransactionCountry = countryCodes;
    }
    return qAndA1FormData;
  }
  return qAndA1FormData;
};
export const getQuestionAndAnswers = (questions, answers, formAnswer, act22DocID, data) => {
  const questionAndAnswers = [];
  for (const property in answers) {
    if (property) {
      const question = questions.find((item) => item.name === property);
      if (question) {
        const formData = getQuestionFormData(questions);
        let answerType = '';
        answerType = getAnswerType(question);
        let { questionDesc } = question;
        if (question.name === 'WitholdInterest') {
          questionDesc = question.documentation[0].description;
        }
        const qAndA = {
          Name: question.name,
          Description: questionDesc,
          Answer: {
            Type: answerType,
            Value: answers[property] ? answers[property] : false,
          },
        };
        qAndA.FormData = typeForm(question, formAnswer, formData);
        qAndA.FormData = setInternationalCountryList(question, data, qAndA.FormData);
        qAndA.Documents = typeKnockout(question, act22DocID);
        questionAndAnswers.push(qAndA);
      }
    }
  }
  return questionAndAnswers;
};

export const returnOccupationInEN = (occupation) => {
  if (occupation) {
    const occupationListInES = OccupationListES();
    const occupationListInEN = OccupationListEN();
    const indexOfOccupation = occupationListInES.indexOf(occupation);
    if (indexOfOccupation === -1) {
      return occupation;
    }
    return occupationListInEN[indexOfOccupation];
  }
  return null;
};
export const returnOccupationInES = (occupation) => {
  if (occupation) {
    const occupationListInES = OccupationListES();
    const occupationListInEN = OccupationListEN();
    const indexOfOccupation = occupationListInEN.indexOf(occupation);
    if (indexOfOccupation === -1) {
      return occupation;
    }
    return occupationListInES[indexOfOccupation];
  }
  return null;
};

export const returnIndustryListInEN = (industry) => {
  if (industry) {
    const industryListInES = industryTypeListES();
    const industryListInEN = industryTypeListEN();
    const indexOfOccupation = industryListInES.indexOf(industry);
    if (indexOfOccupation === -1) {
      return industry;
    }
    return industryListInEN[indexOfOccupation];
  }
  return null;
};

export const createSourceInformation = (data) => ({
  Language: data.language,
  Location: data.location === 'VI' ? 'VI' : 'PR',
  UtmSource: data.utmSource,
  UtmMedium: data.utmMedium,
  UtmCampaign: data.utmCampaign,
  UtmContent: data.utmContent,
  UtmTerm: data.utmTerm,
  BranchCode: data.branchCode,
});
